<script setup lang="ts">
definePageMeta({
  title: "Browse Events",
  auth: false,
});

defineOgImageComponent("Default", {
  title: "Community",
  description:
    "Explore local events, connect with fellow furries, and create moment that you'll never forget!",
});
useHead(() => ({
  title: "Browse",
}));

const route = useRoute();

const isUwu = computed(() => {
  return route.query?.uwu === "true";
});
</script>

<template>
  <main id="main-content">
    <!-- Hero section -->
    <div
      class="-mx-6 -mt-20 px-6 min-h-96 lg:min-h-[46rem] h-full items-center flex relative"
    >
      <nuxt-img
        src="/images/index_background.webp"
        layout="cover"
        class="w-full absolute overflow-hidden inset-0 h-full object-cover object-left-bottom -z-10"
        sizes="100vw lg:1280px md:1024px"
        alt="Welcome to Indonesia Furs Community Platform"
        preload
      />

      <div
        class="mx-auto mt-20 mb-30 lg:mb-40 max-w-2xl container flex flex-col items-center justify-center gap-6 opacity-80"
      >
        <nuxt-img
          v-if="isUwu"
          src="/images/community-kawaii-mode.png"
          sizes="320px lg:512px"
          alt="Kawaii Community logo"
          preload
        />
        <h2 class="text-3xl sm:text-4xl lg:text-5xl text-center !leading-tight">
          Discover, Connect,
          <span class="text-primary">Celebrate Together</span>
        </h2>
        <p class="lg:text-xl text-center !leading-normal">
          Embark on a paw-some journey with Indonesia Furs! Explore local
          events, connect with fellow furries, and create moment that you'll
          never forget. Your next best-day-ever is just a click away. Join us
          and let the adventures begin! 🐾✨
        </p>
      </div>
    </div>

    <div class="space-y-12 container mx-auto mt-12 lg:mt-0">
      <h1>{{ $t("browse_available_events") }}</h1>
      <events-available-events />
    </div>
  </main>
</template>
